'use client';

import { useEffect, useState } from 'react';
type FetchPageDataReturnType = [
  any[],
  {
    hasError: boolean;
    errors: any[];
    responses: PromiseSettledResult<any>[];
  },
];

const smartAwait = async (requests: Promise<any>[]): Promise<FetchPageDataReturnType> => {
  const responses = await Promise.allSettled(requests);

  const values = responses.map(({ value }: any) => value ?? null);
  const hasError = responses.some(({ status }) => status === 'rejected');
  const errors = responses.map(({ reason }: any) => reason);

  return [values, { hasError, errors, responses }];
};

const useSmartAwait = (data, fetcher) => {
  const dataArray = Array.isArray(data) ? data : [data];
  const [isFetching, setIsFetching] = useState(false);
  const [response, setResponse] = useState<FetchPageDataReturnType>([
    [],
    { hasError: false, errors: [], responses: [] },
  ]);

  const [values, { hasError, errors, responses }] = response;

  const refetch = async () => {
    if (data == null) return;

    setIsFetching(true);
    const response = await smartAwait([fetcher(...dataArray)]);
    setIsFetching(false);
    setResponse(response);
  };

  // useEffect(() => {
  //   if (data != null) refetch();
  // }, [data]);

  return [values?.[0], { error: errors?.[0], isFetching, refetch }];
};

export { smartAwait, useSmartAwait };
