import { storyblokInit, apiPlugin } from '@storyblok/js';

import StoryblokClient from 'storyblok-js-client';

const { storyblokApi: Storyblok } = storyblokInit({
  accessToken: process.env.NEXT_PUBLIC_STORYBLOK_PREVIEW,
  use: [apiPlugin],
  apiOptions: { cache: { clear: 'auto', type: 'memory' } },
});

// const Storyblok = new StoryblokClient({
//   // oauthToken: 'TWjBIY0ZhHmvTcKqAEwxFQtt-79272--YveXiYin4cdHZbArrrV',
//   accessToken: process.env.NEXT_PUBLIC_STORYBLOK_PREVIEW,
//   bridge: true,

//   // use: [apiPlugin],
//   // apiOptions: { cache: { clear: 'auto', type: 'memory' } },
// });

export { Storyblok };
