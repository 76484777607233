import { atomWithStorage } from 'jotai/utils';
import FormData from 'form-data';
import { atom } from 'jotai';

const bannerFormDataAtom = atomWithStorage('bannerFormDataAtom', {
  name: '',
  address: '',
  company: '',
  email: '',
  phone: '',
  employeeCount: '',
  signsRequiredCount: '',
  timeframe: '',
  description: '',
});

const bannerFormFileAtom = atom<File[] | null>(null);

const bannerFormDataPayloadAtom = atom((get) => {
  const {
    name,
    address,
    company,
    email,
    phone,
    employeeCount,
    signsRequiredCount,
    timeframe,
    description,
  } = get(bannerFormDataAtom);
  const files = get(bannerFormFileAtom);

  // Create a new form instance
  const form = new FormData();

  // Append text fields to the form
  form.append('name', name);
  form.append('address', address);
  form.append('company', company);
  form.append('email', email);
  form.append('phone', phone);
  form.append('employeeCount', employeeCount);
  form.append('signsRequiredCount', signsRequiredCount);
  form.append('timeframe', timeframe);
  form.append('description', description);

  // `file` can either be a Buffer or a Stream
  // ⚠️ don't forget the 3rd argument, the file name, when appending a file
  if (files) {
    Array.from(files).forEach((file, index) => {
      form.append(`files[${index}]`, file, file.name);
    });
  }

  // console.log({ form });

  return form;
});

export { bannerFormDataAtom, bannerFormDataPayloadAtom, bannerFormFileAtom };
