import { atom } from 'jotai';
import { StoryData } from 'storyblok-js-client';

import { Link } from 'Entities/Link.entity';

import { appAtom } from './App.atom';

interface Settings {
  footerLinks: Link[];
  headerLinks: Link[];
  footerTagline: string;
  contactLinkLabel: string;
}

const settingsAtom = atom<Settings>((get) => get(appAtom)?.settings);

export { settingsAtom };
