'use client';

import useSWR from 'swr';
import axios, { AxiosAdapter, AxiosRequestConfig } from 'axios';
import { FC, useEffect, useRef } from 'react';
import { useAtomValue } from 'jotai/utils';
import ReactGUA from 'react-ga';
import ReactGA4 from 'react-ga4';

import { bannerFormDataAtom, bannerFormDataPayloadAtom, bannerFormFileAtom, isBannerFormValidAtom } from 'Atoms';

import styles from './BannerForm.component.module.css';
import { atom, useAtom } from 'jotai';
import { filenameCreator, useSmartAwait } from 'Utils';
import { PageStoryblok } from 'Entities/Storyblok';
import { usePathname, useRouter } from 'next/navigation';

interface BannerFormComponentProps {
  variant?: string;
  preTitle?: string;
  title?: string;
  text?: string;
  backgroundImages?: PageStoryblok['backgroundImages'];
}

const BannerFormComponent: FC<BannerFormComponentProps> = ({ variant, preTitle, title, text, backgroundImages }) => {
  const $form = useRef<HTMLFormElement>(null);
  const { push } = useRouter();
  // const asPath = usePathname();
  const [bannerFormData, setBannerFormData] = useAtom(bannerFormDataAtom);
  const [bannerFormFiles, setBannerFormFiles] = useAtom(bannerFormFileAtom);
  const bannerFormDataPayload = useAtomValue(bannerFormDataPayloadAtom);
  const [isFormValid, setIsFormValid] = useAtom(isBannerFormValidAtom);

  const payload: [string, any, AxiosRequestConfig] = [
    '/api/send-email',
    bannerFormDataPayload,
    { headers: { 'Content-Type': 'multipart/form-data' } },
  ];
  const [data, { isFetching, error, refetch }] = useSmartAwait(payload, axios.post);

  const backgroundImageUrl = backgroundImages?.[0]?.filename ? filenameCreator(backgroundImages?.[0]) : undefined;

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>) => {
    const { id, value } = event.target;
    setBannerFormData({ ...bannerFormData, [id]: value });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!isFormValid) {
      ReactGUA.event({
        category: 'Form interaction',
        action: 'Form submit invalid',
        label: 'Banner contact form',
      });

      ReactGA4.event('invalid_form_submit', {
        category: 'Form interaction',
        action: 'Form submit invalid',
        label: 'Banner contact form',
      });

      return;
    }

    refetch();

    ReactGA4.send('generate_lead');

    ReactGUA.event({
      category: 'Form interaction',
      action: 'Form submit successful',
      label: 'Banner contact form',
    });

    ReactGA4.event('valid_form_submit', {
      category: 'Form interaction',
      action: 'Form submit successful',
      label: 'Banner contact form',
    });

    ReactGUA.event({
      category: 'Lead generation',
      action: 'Form submit successful',
      label: 'Banner contact form',
    });

    ReactGA4.event('generate_lead', {
      category: 'Lead generation',
      action: 'Form submit successful',
      label: 'Banner contact form',
    });

    push('/thank-you');
  };

  useEffect(() => {
    setIsFormValid(!!$form.current?.checkValidity());
  }, [$form.current, bannerFormData]);

  const { name, email, company, description, employeeCount, phone, address } = bannerFormData;
  const { signsRequiredCount, timeframe } = bannerFormData;

  return (
    <div className={styles.wrapper} data-variant={variant} data-has-image={!!backgroundImageUrl}>
      <div className={styles.background} style={{ '--backgroundImageUrl': `url("${backgroundImageUrl}")` }} />
      <div className="container">
        <div className={styles.layout}>
          <div className={styles.content}>
            <div className={styles.preHeading}>{preTitle || <>THREE DECADES AS LONDON’S TRUSTED SIGN MAKER</>}</div>
            <div className={styles.heading}>{title || <>QUALITY BESPOKE SIGNAGE</>}</div>
            <div className={styles.blurb}>
              {text || (
                <>
                  <p>
                    From independent businesses and private offices to public buildings and cultural centres, we serve
                    organisations across the UK with signs made using both traditional skills and contemporary
                    technologies.
                  </p>
                  <p>
                    As a company built on testimonials and repeat custom, we nurture strong relationships with our
                    clients from the very start.
                  </p>
                  <p> Our ordering process is simple and hassle-free:</p>
                  <ol>
                    <li>
                      <p>Contact us through the form to discuss your design needs and receive a quote.</p>
                    </li>
                    <li>
                      <p>Our team of experts will determine the best materials and construction methods.</p>
                    </li>
                    <li>
                      <p>Feedback or approve the design and quote.</p>
                    </li>
                    <li>
                      <p>Relax while we craft your custom sign.</p>
                    </li>
                    <li>
                      <p>We'll handle installation and ensure everything is to your satisfaction.</p>
                    </li>
                  </ol>
                </>
              )}
            </div>
          </div>
          <form className={styles.form} onSubmit={handleSubmit} ref={$form}>
            <div className={styles.title}>Get a quote</div>

            <div className={styles.halfField}>
              <div className={styles.label}>Name</div>
              <input className={styles.input} id="name" type="text" required value={name} onChange={handleChange} />
            </div>

            <div className={styles.halfField}>
              <div className={styles.label}>Company</div>
              <input
                className={styles.input}
                id="company"
                value={company}
                type="text"
                required
                onChange={handleChange}
              />
            </div>

            <div className={styles.halfField}>
              <div className={styles.label}>Work email</div>
              <input className={styles.input} id="email" type="email" required value={email} onChange={handleChange} />
            </div>

            <div className={styles.halfField}>
              <div className={styles.label}>Work phone number</div>
              <input className={styles.input} id="phone" type="tel" required value={phone} onChange={handleChange} />
            </div>

            <div className={styles.halfField}>
              <div className={styles.label}>Company size</div>
              <select
                className={styles.input}
                id="employeeCount"
                required
                value={employeeCount}
                onChange={handleChange}
              >
                <option value="">Select no. of employees</option>
                <option>1-10 employees</option>
                <option>10-50 employees</option>
                <option>50+ employees</option>
              </select>
            </div>

            <div className={styles.halfField}>
              <div className={styles.label}>Number of signs required</div>
              <select
                className={styles.input}
                id="signsRequiredCount"
                value={signsRequiredCount}
                onChange={handleChange}
                required
              >
                <option value="">Select no. of signs</option>
                <option>1 sign</option>
                <option>2-10 signs</option>
                <option>10+ signs</option>
              </select>
            </div>

            <div className={styles.halfField}>
              <div className={styles.label}>When do you need the sign(s)?</div>
              <select className={styles.input} id="timeframe" value={timeframe} onChange={handleChange} required>
                <option value="">Select a timeframe</option>
                <option>ASAP</option>
                <option>Regular scheduling</option>
              </select>
            </div>

            <div className={styles.halfField}>
              <div className={styles.label}>Upload relevant design files</div>

              <label htmlFor="file" className={styles.input}>
                {bannerFormFiles?.length ? `${bannerFormFiles?.length} files selected` : 'Select files'}{' '}
                <i className="fa fa-upload" />
              </label>
              <input
                type="file"
                className={styles.input}
                id="file"
                multiple
                // value={timeframe}
                onChange={() => setBannerFormFiles(event.target.files)}
              />
            </div>

            <div className={styles.fullField}>
              <div className={styles.label}>Installation address</div>
              <textarea className={styles.input} required id="address" value={address} onChange={handleChange} />
            </div>

            <div className={styles.fullField}>
              <div className={styles.label}>Job description</div>
              <textarea
                className={styles.input}
                required
                id="description"
                value={description}
                onChange={handleChange}
              />
            </div>

            <button type="submit" className={styles.submit}>
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export { BannerFormComponent };
