'use client';

import { Provider } from 'jotai';
import React, { FC, useEffect } from 'react';
import { useUpdateAtom } from 'jotai/utils';
// import { useLoadingState, useInitUser } from 'utils/hooks';
import { appAtom } from 'Atoms';

// will set initial state to an atom which can then be used to derive new atoms
const JotaiProvider: FC<{ pageProps: any }> = ({ pageProps, children }) => (
  <Provider initialValues={[[appAtom, pageProps]] as any}>
    <JotaiContainer pageProps={pageProps}>{children}</JotaiContainer>
  </Provider>
);

const JotaiContainer: FC<{ pageProps: any }> = ({ pageProps, children }) => {
  const setInitialProps = useUpdateAtom(appAtom);

  useEffect(() => {
    setInitialProps(pageProps);
  }, [pageProps]);

  return <>{children}</>;
};

export { JotaiProvider };
