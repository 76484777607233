"use client";

import { useState } from 'react';
import Image, { ImageProps } from 'next/legacy/image';

const Img = (props: ImageProps) => {
  const [hasLoaded, setHasLoaded] = useState(false);

  return <Image {...props} onLoadingComplete={() => setHasLoaded(true)} data-opacity={hasLoaded} />;
};

export { Img };
